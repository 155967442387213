<template>
  <base-section
    id="VideoShort"
    class="white"
    space="36"
  >
    <v-container>
      <base-section-heading title="Aptamimetics explained in 120s (German)" />
      <v-row
        align="self-center"
        justify="center"
      >
        <iframe
          :width="width"
          :height="315"
          :src="video"
          title="Aptamimetics in 120s (German)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>

  export default {
    name: 'SectionVideoShort',

    data: () => ({
      video: 'https://www.youtube-nocookie.com/embed/HJMX-0lpuW8?si=pnp4aG2uBa2_53WA', // Replace with your YouTube video ID
      width: 560, // Set your preferred width
      height: 315, // Set your preferred height
    }),

    computed: {
      items () {
        const today = new Date().getTime()

        return this.supporters.filter((el) => el.exp === false || today <= new Date(el.exp).getTime())
      },

      itemlength () {
        return this.items.length
      },
    },
  }
</script>
